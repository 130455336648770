<template>
  
      			<header id="header" class="header-transparent" data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': false, 'stickyStartAt': 1, 'stickyHeaderContainerHeight': 70}">
				<div class="header-body header-border-bottom overflow-visible">
					<div class="header-container container">
						<div class="header-row py-3">
							<div class="header-column flex-row w-auto">
								<div class="header-logo">
									<a href="index.html">
										<img src="img/demos/product-landing/logo.png" alt="Porto" width="123" height="43" />
									</a>
								</div>
							</div>
							<div class="header-column w-100 pl-lg-5">
								<div class="header-nav w-100 p-0">
									<div class="header-nav header-nav-line header-nav-bottom-line header-nav-bottom-line-effect-1 justify-content-start w-100">
										<div class="header-nav-main header-nav-main-arrows header-nav-main-effect-2 header-nav-main-sub-effect-1 w-100">
											<nav class="collapse w-100">
												<ul class="nav nav-pills flex-column flex-lg-row w-100" id="mainNav">
													<li class="dropdown">
														<a class="dropdown-item active" data-hash data-hash-offset="70" href="#intro">เกริ่นนำ</a>
													</li>
													<li class="dropdown">
														<a class="dropdown-item" data-hash data-hash-offset="70" href="#Register">การขอใช้บริการ</a>
													</li>
													<li class="dropdown no-line-effect">
														<a href="#" class="dropdown-item dropdown-toggle">ขั้นตอนการใช้งาน ด้วย CMU Mobile App</a>
														<ul class="dropdown-menu">
															<li><a class="dropdown-item" data-hash data-hash-offset="70" href="#FirstStep" >การติดตั้งใบรับรองสำหรับเซ็นเอกสารอิเล็กทรอนิกส์</a></li>
															<li><a class="dropdown-item" data-hash data-hash-offset="70" href="#SecordStep" >การเตรียมลายมือชื่อเพื่อใช้กับลายมือชื่อดิจิทัล</a></li>
															<li><a class="dropdown-item" data-hash data-hash-offset="90" href="#ThirdStep" >การใช้งาน การลงลายมือชื่อดิจิทัล</a></li>
															<li><a class="dropdown-item" data-hash data-hash-offset="90" href="#Receiver" >การเตรียมการเพื่อเปิดเอกสารที่มีการลงลายมือชื่อดิจิทัล</a></li>
														</ul>
													</li>
												
												</ul>
											</nav>
										</div>
									</div>
								</div>
							</div>
							<div class="header-column flex-row w-auto">
								<div class="header-nav-features pl-0 ml-1">
									<div class="header-nav-feature header-nav-features-cart header-nav-features-cart-big d-inline-flex top-2 ml-2">

										<div class="header-nav-features-dropdown" id="headerTopCartDropdown">
											<ol class="mini-products-list">
												<li class="item">
													<a href="#" title="Camera X1000" class="product-image"><img src="img/demos/product-landing/product-carousel-1.jpg" alt="Porto Headphone"></a>
													<div class="product-details">
														<p class="product-name">
															<a href="#">Porto Headphone </a>
														</p>
														<p class="qty-price">
															 1X <span class="price">$350</span>
														</p>
														<a href="#" title="Remove This Item" class="btn-remove"><i class="fas fa-times"></i></a>
													</div>
												</li>
											</ol>
											<div class="totals">
												<span class="label">Total:</span>
												<span class="price-total"><span class="price">$350</span></span>
											</div>
											<div class="actions">
												<a class="btn btn-primary btn-block" href="demo-product-landing-checkout.html">Checkout</a>
											</div>
										</div>
									</div>
								</div>
								<button class="btn header-btn-collapse-nav ml-3" data-toggle="collapse" data-target=".header-nav-main nav">
									<i class="fas fa-bars"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</header>

</template>



