<template>
  <div class="home">
    <Navigationpage />
  </div>
</template>

<script>
// @ is an alias to /src
import Navigationpage from "@/components/Navigationpage.vue";

export default {
  name: "Home",
  components: {
    Navigationpage,
  },
};
</script>
